/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function IconActions(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    { overrides: { IconActions: {} }, variantValues: { name: "Add" } },
    {
      overrides: {
        IconActions: {
          paths: [
            {
              d: "M8.75 0C11.0706 -2.77556e-16 13.2962 0.921872 14.9372 2.56282C16.5781 4.20376 17.5 6.42936 17.5 8.75C17.5 11.0706 16.5781 13.2962 14.9372 14.9372C13.2962 16.5781 11.0706 17.5 8.75 17.5C6.42936 17.5 4.20376 16.5781 2.56282 14.9372C0.921872 13.2962 6.93889e-16 11.0706 0 8.75C-2.77556e-16 6.42936 0.921872 4.20376 2.56282 2.56282C4.20376 0.921872 6.42936 1.94289e-15 8.75 0L8.75 0ZM4.375 8.75C4.375 8.94891 4.45402 9.13968 4.59467 9.28033C4.73532 9.42098 4.92609 9.5 5.125 9.5L12.375 9.5C12.5739 9.5 12.7647 9.42098 12.9053 9.28033C13.046 9.13968 13.125 8.94891 13.125 8.75C13.125 8.55109 13.046 8.36032 12.9053 8.21967C12.7647 8.07902 12.5739 8 12.375 8L5.125 8C5.02651 8 4.92898 8.0194 4.83799 8.05709C4.74699 8.09478 4.66431 8.15003 4.59467 8.21967C4.52503 8.28931 4.46978 8.37199 4.43209 8.46299C4.3944 8.55398 4.375 8.65151 4.375 8.75L4.375 8.75Z",
              fill: "rgba(0,75,133,1)",
              fillRule: "nonzero",
              style: { transform: "translate(6.25%, 6.25%)" },
            },
          ],
        },
      },
      variantValues: { name: "less" },
    },
    {
      overrides: {
        IconActions: {
          paths: [
            {
              d: "M0.000475536 0L0.000475536 12.2222C-0.00469285 12.436 0.0323115 12.6487 0.109375 12.8482C0.186438 13.0476 0.302049 13.23 0.449603 13.3848C0.597157 13.5395 0.773761 13.6637 0.969326 13.7502C1.16489 13.8367 1.37558 13.8838 1.58936 13.8889L11.7171 13.8889C11.9333 13.8875 12.147 13.843 12.3458 13.7581C12.5445 13.6733 12.7244 13.5496 12.8749 13.3945C13.0254 13.2394 13.1435 13.0558 13.2223 12.8545C13.3011 12.6533 13.339 12.4383 13.3338 12.2222L13.3338 0L0.000475536 0ZM5.00048 11.1111L3.88936 11.1111L3.88936 2.77778L5.00048 2.77778L5.00048 11.1111ZM9.44492 11.1111L8.33381 11.1111L8.33381 2.77778L9.44492 2.77778L9.44492 11.1111Z",
              fill: "rgba(191,64,64,1)",
              fillRule: "nonzero",
              style: { transform: "translate(16.66%, 25%)" },
            },
            {
              d: "M14.85 1.66667L10.5556 1.66667L10.5556 1.11111C10.5556 0.816426 10.4385 0.533811 10.2301 0.325437C10.0217 0.117063 9.73913 2.46716e-16 9.44444 0L6 0C5.72497 0.0276376 5.47012 0.156794 5.28521 0.362249C5.10031 0.567704 4.99862 0.834704 5 1.11111L5 1.66667L0.555556 1.66667C0.408213 1.66667 0.266905 1.7252 0.162719 1.82939C0.0585317 1.93357 2.46716e-16 2.07488 0 2.22222C2.46716e-16 2.36956 0.0585317 2.51087 0.162719 2.61506C0.266905 2.71925 0.408213 2.77778 0.555556 2.77778L14.85 2.77778C14.9973 2.77778 15.1387 2.71925 15.2428 2.61506C15.347 2.51087 15.4056 2.36956 15.4056 2.22222C15.4056 2.07488 15.347 1.93357 15.2428 1.82939C15.1387 1.7252 14.9973 1.66667 14.85 1.66667Z",
              fill: "rgba(191,64,64,1)",
              fillRule: "nonzero",
              style: { transform: "translate(11.11%, 5.56%)" },
            },
          ],
        },
      },
      variantValues: { name: "delete" },
    },
    {
      overrides: {
        IconActions: {
          paths: [
            {
              d: "M12.5035 14.9519L0.836836 14.9519C0.615822 14.9519 0.403861 15.0397 0.24758 15.1959C0.0913 15.3522 0.00350246 15.5642 0.00350246 15.7852C0.00350246 16.0062 0.0913 16.2182 0.24758 16.3745C0.403861 16.5307 0.615822 16.6185 0.836836 16.6185L12.5035 16.6185C12.7245 16.6185 12.9365 16.5307 13.0928 16.3745C13.249 16.2182 13.3368 16.0062 13.3368 15.7852C13.3368 15.5642 13.249 15.3522 13.0928 15.1959C12.9365 15.0397 12.7245 14.9519 12.5035 14.9519ZM0.836836 13.2852L0.911836 13.2852L4.38684 12.9685C4.7675 12.9306 5.12353 12.7629 5.39517 12.4935L12.8952 4.99353C13.1863 4.68601 13.3436 4.27562 13.3327 3.85231C13.3217 3.429 13.1434 3.02729 12.8368 2.7352L10.5535 0.451867C10.2555 0.171947 9.86499 0.011333 9.45628 0.000577298C9.04756 -0.0101784 8.64915 0.129675 8.33684 0.393534L0.836836 7.89353C0.567474 8.16517 0.399756 8.5212 0.361836 8.90187L0.00350246 12.3769C-0.00772341 12.4989 0.0081144 12.622 0.0498868 12.7372C0.0916592 12.8524 0.158338 12.957 0.245169 13.0435C0.323036 13.1208 0.415383 13.1819 0.516914 13.2233C0.618445 13.2648 0.727164 13.2858 0.836836 13.2852ZM9.39517 1.61853L11.6702 3.89353L10.0035 5.51853L7.77017 3.2852L9.39517 1.61853Z",
              fill: "rgba(0,75,133,1)",
              fillRule: "nonzero",
              style: { transform: "translate(16.65%, 8.57%)" },
            },
          ],
        },
      },
      variantValues: { name: "edit" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Icon
      width="20px"
      height="20px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 20, height: 20 }}
      paths={[
        {
          d: "M8.33333 0C12.9358 0 16.6667 3.73083 16.6667 8.33333C16.6667 12.9358 12.9358 16.6667 8.33333 16.6667C3.73083 16.6667 0 12.9358 0 8.33333C0 3.73083 3.73083 0 8.33333 0ZM8.33333 4.16667C8.1823 4.16667 8.03638 4.22137 7.92256 4.32064C7.80873 4.41991 7.73471 4.55704 7.71417 4.70667L7.70833 4.79167L7.70833 7.70833L4.79167 7.70833C4.63331 7.70838 4.48088 7.76854 4.36517 7.87664C4.24946 7.98475 4.1791 8.13275 4.16831 8.29073C4.15752 8.44872 4.2071 8.60491 4.30703 8.72775C4.40696 8.85059 4.54979 8.93091 4.70667 8.9525L4.79167 8.95833L7.70833 8.95833L7.70833 11.875C7.70838 12.0334 7.76854 12.1858 7.87664 12.3015C7.98475 12.4172 8.13275 12.4876 8.29073 12.4984C8.44872 12.5091 8.60491 12.4596 8.72775 12.3596C8.85059 12.2597 8.93091 12.1169 8.9525 11.96L8.95833 11.875L8.95833 8.95833L11.875 8.95833C12.0334 8.95828 12.1858 8.89813 12.3015 8.79002C12.4172 8.68192 12.4876 8.53392 12.4984 8.37593C12.5091 8.21795 12.4596 8.06176 12.3596 7.93892C12.2597 7.81608 12.1169 7.73575 11.96 7.71417L11.875 7.70833L8.95833 7.70833L8.95833 4.79167C8.95833 4.62591 8.89248 4.46694 8.77527 4.34972C8.65806 4.23251 8.49909 4.16667 8.33333 4.16667Z",
          fill: "rgba(0,75,133,1)",
          fillRule: "nonzero",
          style: { transform: "translate(8.33%, 8.33%)" },
        },
      ]}
      {...getOverrideProps(overrides, "IconActions")}
      {...rest}
    ></Icon>
  );
}
